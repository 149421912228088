<template>
  <div class="card card-body border-0 px-0 filter-panel" :class="isRTLPage ? 'right-align' : 'left-align'">
    <div id="accordionSelect" class="accordion sidePanel">
      <sidebar-filter-select
        v-if="options.chain"
        :item="options.chain"
        @change="fireFilterSelect"
      />
    </div>
    <div id="accordionParent1" class="accordion sidePanel">
      <sidebar-filter-slide
        v-if="options.priceSlider"
        :item="options.priceSlider"
        @change="fireFilterPrice"
      />
    </div>
    <div class="accordion sidePanel" v-for="(val, inx) in options" :key="inx">
      <SidebarFilterCheckbox :item='val' @change="filterCheckbox" v-if="val && val.kind === 'hotel-basis' && route !=='home'"/>
      <SidebarFilterCheckbox :item='val' @change="filterCheckbox" @closePanel="closeMobilePanel" v-if="val && val.kind === 'hotel-name' && route !=='home'" :filterType="`lesiurTab`"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {
    SidebarFilterSlide: () => import('@/components/searchResult/atoms/SidebarFilterSlide'),
    SidebarFilterCheckbox: () => import('@/components/searchResult/atoms/SidebarFilterCheckbox'),
    SidebarFilterSelect: () => import('@/components/searchResult/atoms/SidebarFilterSelect'),
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    route: {
      type: String,
      default: null,
    },
    updateCom: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters({
      isRTLPage: 'GET_IS_RLT_PAGE',
    }),
  },
  data() {
    return {
      filterConditions: {},
      element: null,
    };
  },
  watch: {
    options() {
    },
  },
  created() {
  },
  methods: {
    fireFilterPrice(pValue) {
      this.$set(this.filterConditions, 'price', pValue);
      this.$emit('change', this.filterConditions);
    },
    filterCheckbox(value) {
      this.$set(this.filterConditions, value.kind, value.value);
      this.$emit('change', this.filterConditions);
    },
    fireFilterSelect(pValue) {
      this.$set(this.filterConditions, pValue.kind, pValue.value);
      this.$emit('change', this.filterConditions);
    },
    closeMobilePanel() {
      this.$emit('closeSearchMobile');
    },
  },
};
</script>

<style scoped>
  .accordion{
    margin: 5px 0;
  }
  .filter-panel{
    position: sticky;
    top: 25px;
    height: 100vh;
    overflow-y: scroll;
    margin-top: 20px;
    direction: ltr;
  }
  .filter-panel div{
    width: calc(100% - 3px);
    margin-left: 3px;
  }
  .left-align.filter-panel div,
  .left-align.filter-panel .accordion.sidePanel {
    direction: ltr;
    text-align: left;
  }
  .right-align.filter-panel div,
  .right-align.filter-panel .accordion.sidePanel {
    direction: rtl;
    text-align: right;
  }
  @media (max-width: 479px) {
  .filter-panel{
    height: auto;
  }
}
</style>
